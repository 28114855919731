import { memo, useEffect, useState } from 'react';
import { RootState } from 'store';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';

import { ReactComponent as ArrowLeftIcon } from 'assets/icons/arrow-left.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-dark.svg';

import { CurtainCommentCard } from 'curtain/components/CurtainCommentCard';
import { CurtainCommentForm } from 'curtain/components/CurtainCommentForm';
import { ICurtainTopic } from 'curtain/interfaces/ICurtainTopic';

import {
  getCurtainComments,
  getNumberOfUnreadTopics,
  markTopicAsRead,
  resolveTopic,
  setActiveChannel,
  setActiveLineItemID,
  setActiveStyleID,
  setActiveTopic,
  setActiveTopicToResolved,
  setActiveTopicToUnresolved,
  setBackToChanel,
  setCurtainComments,
  unresolveTopic,
} from 'curtain/store/curtainActions';

import EmptyState from 'shared/components/EmptyState';
import SVG from 'shared/components/SVG';
import ScrollbarsCustom from 'shared/components/ScrollbarsCustom';
import UtilService from 'shared/services/util.service';
import { ButtonIcon } from 'shared/components/Button';
import { CurtainComment } from 'shared/interface/CurtainComment';
import { H3, H5 } from 'shared/components/Typography';
import { OrderStatusEnums } from 'order/enums/orderEnums';
import { ResolveButton } from 'shared/components/ResolveButton';
import { Spacer } from 'shared/components/Layout';
import { Tooltip } from 'shared/components/Tooltip';
import { Wrapper } from 'shared/components/Wrapper';
import { mineShaft } from 'shared/config/Colors';
import { orderHeaderHeightWithoutCollaborationActions } from 'shared/config/Variables';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useCanEditOrder } from 'shared/hooks/useCanEditOrder';
import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { useIsOrderInStatus } from 'shared/hooks/useIsOrderInStatus';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';

import { useParams } from 'react-router';
import { OrderPageParams } from 'order/interfaces/OrderPageParams';
import { ChannelTypeEnum } from 'curtain/enums/ChannelTypeEnum';
import {
  updateLineItemNoOfUnreadTopics,
  updateStyleNoOfUnreadTopics,
} from 'order/store/orderActions';
import Loader from '../../shared/components/Loader';
import { TopicMeta } from './TopicMeta';

const TopicContainer = styled(Wrapper)<{ canEditOrder: boolean }>`
  height: calc(
    100vh -
      ${({ canEditOrder }) =>
        orderHeaderHeightWithoutCollaborationActions -
        (canEditOrder ? 0 : 44)}px
  );
`;

const TopicHeader = styled(Wrapper)`
  padding: 14px;
  min-height: 65px;
  border-bottom: 1px solid ${({ theme }) => theme.alto};
`;

const TopicSubHeader = styled(Wrapper)`
  padding: 14px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.alto};
`;

const CurtainCommentCardWrapper = styled.div`
  overflow-y: auto;
  height: 100%;
`;

const ChannelSubHeader = styled(Wrapper)`
  background-color: ${({ theme }) => theme.wildSandTwo};
  border-bottom: 1px solid ${({ theme }) => theme.alto};
  padding: 14px 20px;
  min-height: 65px;

  h3 {
    font-weight: 700;
  }
`;

export const CurtainTopicSection = memo(() => {
  const dispatch = useAppDispatch();

  const canEditOrder = useCanEditOrder();
  const { orderId } = useParams<OrderPageParams>();

  const [commentCardWrapperRef, setCommentCardWrapperRef] =
    useState<Scrollbars | null>(null);

  const curtainActiveTopic: ICurtainTopic = useSelector(
    (state: RootState) => state.curtainReducer.curtainActiveTopic
  );

  const curtainComments = useSelector(
    (state: RootState) => state.curtainReducer.comments
  );

  const backToChannel = useSelector(
    (state: RootState) => state.curtainReducer.backToChannel
  );

  const activeChannel = useSelector(
    (state: RootState) => state.curtainReducer.activeChannel
  );

  const activeEntityName = useSelector(
    (state: RootState) => state.curtainReducer.activeEntityName
  );

  const cannotResolve = useIsOrderInStatus({
    greaterThen: true,
    status: OrderStatusEnums['Not Submitted'],
  });

  const [activeTopicResolveProgress, setActiveTopicResolveProgress] =
    useState(false);

  const [commentsLoading, setCommentsLoading] = useState(false);

  const canChangeTopicPriority = useHasPermissions(
    userPermissionsValues.TOPIC_CHANGE_PRIORITY
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const numberOfUnreadTopics = useSelector(
    (state: RootState) => state.curtainReducer.numberOfUnreadTopics
  );

  const orderLineItems = useSelector(
    (state: RootState) => state.orderReducer.lineItems
  );

  const styles = useSelector((state: RootState) => state.orderReducer.styles);

  useEffect(() => {
    if (curtainComments === null && curtainActiveTopic) {
      setCommentsLoading(true);

      dispatch(
        getCurtainComments(curtainActiveTopic?.id, setCommentsLoading, () =>
          toast.error('Failed to fetch comments.')
        )
      );
    }
  }, [curtainActiveTopic]);

  useEffect(() => {
    if (curtainComments && commentCardWrapperRef) {
      commentCardWrapperRef.scrollToBottom();
    }
  }, [curtainComments, commentCardWrapperRef]);

  const loadNumberOfUnreadTopics = () => {
    dispatch(getNumberOfUnreadTopics(orderId));

    if (activeChannel && activeChannel.entityId) {
      if (activeChannel.type === ChannelTypeEnum.STYLE) {
        const activeStyle = styles?.find(
          (style) => style.id === activeChannel.entityId
        );

        if (activeStyle && activeStyle.unreadTopicCount > 0) {
          dispatch(
            updateStyleNoOfUnreadTopics({
              id: activeChannel.entityId,
              topicCount: activeStyle.unreadTopicCount - 1,
            })
          );
        }
      } else {
        const activeLineItem = orderLineItems?.find(
          (lineItem) => lineItem.lineItemId === activeChannel.entityId
        );
        if (activeLineItem && activeLineItem.numberOfUnreadTopics > 0) {
          dispatch(
            updateLineItemNoOfUnreadTopics({
              lineItemId: activeChannel.entityId,
              topicCount: activeLineItem.numberOfUnreadTopics - 1,
            })
          );
        }
      }
    }
  };

  const navigateToChannel = () => {
    dispatch(setCurtainComments(null));
    dispatch(setActiveTopic(null));

    if (backToChannel) {
      dispatch(setActiveChannel(backToChannel));
      dispatch(
        backToChannel.type === 1
          ? setActiveStyleID(backToChannel.id)
          : setActiveLineItemID(backToChannel.id)
      );

      // make sure to clear back to channel after going back.
      setTimeout(() => {
        dispatch(setBackToChanel(null));
      }, 0);
    }
  };

  const goBack = () => {
    if (isUserCSR && (numberOfUnreadTopics ?? 0) > 0) {
      dispatch(
        markTopicAsRead(curtainActiveTopic.id, () => {
          loadNumberOfUnreadTopics();
          navigateToChannel();
        })
      );
      return;
    }

    navigateToChannel();
  };

  const onAttachmentsAddedHandler = () => {
    if (commentCardWrapperRef) {
      commentCardWrapperRef.scrollToBottom();
    }
  };

  const onSuccessResolveOtherTopic = () => {
    dispatch(setActiveTopicToResolved());
    setActiveTopicResolveProgress(false);
    loadNumberOfUnreadTopics();
    toast.success('You have successfully resolved a topic.');
  };

  const onFailedResolveOtherTopic = () => {
    setActiveTopicResolveProgress(false);
    toast.error("Topic hasn't been resolved. Please try again.");
  };

  const onSuccessUnresolveOtherTopic = () => {
    setActiveTopicResolveProgress(false);
    dispatch(setActiveTopicToUnresolved());
    toast.success('You have successfully unresolved a topic.');
  };

  const onFailedUnresolveOtherTopic = () => {
    setActiveTopicResolveProgress(false);
    toast.error("Topic hasn't been unresolved. Please try again.");
  };

  return (
    <TopicContainer flex column canEditOrder={canEditOrder}>
      <TopicHeader flex middle>
        <ButtonIcon onClick={goBack} disabled={activeTopicResolveProgress}>
          <ArrowLeftIcon />
        </ButtonIcon>
        <Spacer w="16px" />
        <H3>Topics</H3>
      </TopicHeader>
      <ChannelSubHeader flex middle between>
        <H3>
          {activeEntityName?.length ? activeEntityName : activeChannel?.name}
        </H3>
      </ChannelSubHeader>
      <TopicSubHeader flex between>
        <div>
          <Wrapper
            position="relative"
            withTooltip={(curtainActiveTopic?.name?.length ?? 0) > 180}
            tooltipDelay={500}
          >
            <H5 wordBreak="break-word">
              {UtilService.trimStringToNumberOfChars(
                curtainActiveTopic?.name,
                180
              )}
            </H5>

            <Tooltip maxWidth={220} minWidth={220} position="bottom">
              {curtainActiveTopic?.name}
            </Tooltip>
          </Wrapper>

          <TopicMeta topic={curtainActiveTopic} />
        </div>

        {!curtainActiveTopic.isDeleted &&
          (!cannotResolve || canChangeTopicPriority) && (
            <ResolveButton
              disabled={activeTopicResolveProgress}
              onClick={() => {
                setActiveTopicResolveProgress(true);
                if (curtainActiveTopic.isResolved) {
                  dispatch(
                    unresolveTopic(
                      curtainActiveTopic.id,
                      onSuccessUnresolveOtherTopic,
                      onFailedUnresolveOtherTopic
                    )
                  );
                } else {
                  dispatch(
                    resolveTopic(
                      curtainActiveTopic?.id,
                      onSuccessResolveOtherTopic,
                      onFailedResolveOtherTopic
                    )
                  );
                }
              }}
              className={`${curtainActiveTopic.isResolved && 'resolved-topic'}`}
            >
              {activeTopicResolveProgress ? (
                <Loader size={14} />
              ) : (
                <SVG icon={<CheckIcon />} color={mineShaft} />
              )}
            </ResolveButton>
          )}
      </TopicSubHeader>

      <CurtainCommentCardWrapper>
        <ScrollbarsCustom
          ref={(el) => setCommentCardWrapperRef(el)}
          autoHide
          autoHideTimeout={500}
          autoHideDuration={300}
          paddingRight={0}
        >
          {commentsLoading && !curtainComments && <Loader size={30} />}

          {!commentsLoading && curtainComments?.length === 0 && (
            <EmptyState
              title="No comments yet"
              titleSize={20}
              message="Add one if there is something unclear."
            />
          )}

          {curtainComments
            ?.filter((comment) => !comment.isDeleted)
            .map((comment: CurtainComment) => (
              <CurtainCommentCard
                key={comment.id}
                data={comment}
                isUserCSR={isUserCSR}
              />
            ))}
        </ScrollbarsCustom>
      </CurtainCommentCardWrapper>
      {!curtainActiveTopic.isDeleted && (
        <CurtainCommentForm attachmentsAdded={onAttachmentsAddedHandler} />
      )}
    </TopicContainer>
  );
});
