import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { RootState } from 'store';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import noImagePlaceholderSrc from 'assets/images/no-image-placeholder.svg';

import { SpecificationsOptionsEnum } from 'order/wizard/orderStyles/enums/SpecificationsOptionsEnum';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import {
  getFinishImpressionData,
  getFinishImpressionOptions,
} from 'order/wizard/orderStyles/productLines/store/specifications/orderStylesSpecificationActions';

import { useHasPermissions } from 'shared/hooks/useHasPermissions';
import { userPermissionsValues } from 'shared/enum/userPermissionsEnum';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { H4 } from 'shared/components/Typography';
import { Input } from 'shared/components/Input';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Wrapper } from 'shared/components/Wrapper';
import FormError from 'shared/components/FormError';
import Img from 'shared/components/Img';
import UtilService from 'shared/services/util.service';

import { useScrollToElement } from '../../../../../../../shared/hooks/useScrollToElement';
import { upchargeValidation } from '../../../../../../../shared/validations/validations';

const CirclesContainer = styled.div<{ disabled: boolean }>`
  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    opacity: 0.4;
  `}
  display: grid;
  gap: 7px;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;
`;

const CircleButton = styled(TabbableButton)`
  border-radius: 50%;
  width: 96px;
  height: 96px;
  background: #c4c4c4;
  position: relative;

  &::before {
    content: '';
    border-radius: 50%;
    height: 100px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: -2px;
    transform: translateX(-50%) scale(1.1);
    transition: 300ms ease;
    width: 100px;
    pointer-events: none;
    box-shadow: inset 0 0 0 3px ${({ theme }) => theme.kashmirBlue},
      inset 0 0 0 6px white;
  }

  &.selected {
    &::before {
      opacity: 1;
      transform: translateX(-50%) scale(1);
    }

    + div {
      color: ${({ theme }) => theme.kashmirBlue};
      font-weight: 600;
    }
  }
`;

const CircleButtonLabel = styled.div`
  color: ${({ theme }) => theme.nevada};
  font-size: 14px;
  margin-top: 5px;
  max-width: 96px;
  text-align: center;
  word-wrap: break-word;
`;

const ColorImage = styled(Img)`
  display: block;
  margin: 0 auto;
  border-radius: 50%;
`;

const ImpressionFinish = () => {
  const dispatch = useAppDispatch();
  const { register, control, setValue, watch, formState } =
    useFormContext<StyleSpecifications>();

  const finishImpressionWatched = watch('finishImpression');
  const specificationOptionWatched = watch('specificationOption');

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const finishImpressionOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions.finishImpressionOptions
  );

  const storedFinishImpression = useSelector(
    (state: RootState) => state.orderStylesReducer.style?.finishImpression
  );

  const finishImpressionData = useSelector(
    (state: RootState) => state.orderStylesReducer.finishImpressionData
  );

  const isUserCSR = useHasPermissions(
    userPermissionsValues.CSR_VIEW_ORDERBOARD
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const onSelectFinishImpression = (singleImpression: SelectOptionProps) => {
    if (selectedProductLine) {
      dispatch(
        getFinishImpressionData({
          productLineId: selectedProductLine.id,
          finishImpressionId: singleImpression.value,
        })
      );
    }

    setValue('finishImpression', singleImpression, {
      shouldValidate: true,
      shouldDirty: true,
    });
  };

  useEffect(() => {
    if (finishImpressionData?.upcharge && formState.isDirty) {
      UtilService.withDecimal(
        'finishColorUpcharge',
        finishImpressionData.upcharge.toString(),
        setValue
      );
    }
  }, [finishImpressionData]);

  useEffect(() => {
    if (
      storedFinishImpression &&
      !formState.dirtyFields.specificationOption &&
      specificationOptionWatched === SpecificationsOptionsEnum.SELECT_IMPRESSION
    ) {
      // reset to the default impression data.
      const defaultImpressionData = finishImpressionOptions?.find(
        (finishImpressionOption) =>
          finishImpressionOption.value === storedFinishImpression.id
      );

      if (defaultImpressionData && selectedProductLine) {
        setValue('finishImpression', defaultImpressionData);

        UtilService.withDecimal(
          'finishColorUpcharge',
          (storedFinishImpression.upcharge ?? 0).toString(),
          setValue
        );
      }
    }
  }, [specificationOptionWatched, selectedProductLine, storedFinishImpression]);

  useEffect(() => {
    if (
      selectedProductLine &&
      formState.dirtyFields.specificationOption &&
      specificationOptionWatched === SpecificationsOptionsEnum.SELECT_IMPRESSION
    ) {
      dispatch(getFinishImpressionOptions(selectedProductLine.id));
    }
  }, [specificationOptionWatched, selectedProductLine]);

  const finishImpressionRef = useRef<HTMLDivElement | null>(null);

  useScrollToElement({
    errors: formState.errors,
    error: formState.errors.finishImpression,
    ref: finishImpressionRef,
    fieldName: 'finishImpression',
  });

  return (
    <>
      <Wrapper flex ref={finishImpressionRef}>
        <FormElement flexGrow>
          <FormLabel>Impression Name</FormLabel>
          <H4>
            {finishImpressionWatched ? finishImpressionWatched.label : 'N/A'}
          </H4>

          <FormError
            label="Impression name"
            error={formState.errors.finishImpression as FieldError}
            validationSchema={{ required: true }}
          />
        </FormElement>

        <FormElement maxWidth={112}>
          <FormLabel>Upcharge %</FormLabel>
          <Input
            {...register('finishColorUpcharge', upchargeValidation())}
            type="text"
            placeholder="0.00"
            disabled
          />
          <FormError
            label="Finish Color Upcharge"
            error={formState.errors.finishColorUpcharge as FieldError}
            validationSchema={upchargeValidation()}
          />
        </FormElement>
      </Wrapper>

      <CirclesContainer disabled={!canEdit}>
        {finishImpressionOptions?.map((singleImpression) => (
          <Controller
            name="finishImpression"
            rules={{ required: true }}
            control={control}
            key={singleImpression.value}
            render={() => (
              <Wrapper flex column middle>
                <CircleButton
                  type="button"
                  onClick={() => onSelectFinishImpression(singleImpression)}
                  className={
                    singleImpression?.value === finishImpressionWatched?.value
                      ? 'selected'
                      : ''
                  }
                >
                  <ColorImage
                    width="96"
                    height="96"
                    alt={`${singleImpression.label}`}
                    src={singleImpression.imageUrl || noImagePlaceholderSrc}
                  />
                </CircleButton>
                <CircleButtonLabel>{singleImpression.label}</CircleButtonLabel>
              </Wrapper>
            )}
          />
        ))}
      </CirclesContainer>
      <Spacer h="48px" />

      {isUserCSR && (
        <>
          <Wrapper flex>
            <Wrapper flex wHalf column>
              <FormElement>
                <FormLabel>Finish Name</FormLabel>
                <H4>
                  {UtilService.displayNotAvailableIfEmpty(
                    finishImpressionData?.finishColor.name
                  )}
                </H4>
              </FormElement>
              <FormElement>
                <FormLabel>Varnish Sheen</FormLabel>
                <H4>
                  {UtilService.displayNotAvailableIfEmpty(
                    finishImpressionData?.varnishSheen.name
                  )}
                </H4>
              </FormElement>
            </Wrapper>

            {finishImpressionData?.finishEffects && (
              <Wrapper flex wHalf column>
                {finishImpressionData.finishEffects.map(
                  (finishEffect, index: number) => (
                    <FormElement key={finishEffect.id}>
                      <FormLabel>Finish Effect {index + 1}</FormLabel>
                      <H4>
                        {UtilService.displayNotAvailableIfEmpty(
                          finishEffect?.name
                        )}
                      </H4>
                    </FormElement>
                  )
                )}
              </Wrapper>
            )}
          </Wrapper>
        </>
      )}
    </>
  );
};

export default ImpressionFinish;
