import { Control, Controller, useFormContext, useWatch } from 'react-hook-form';
import { FC, useEffect, useState } from 'react';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as TrashBinIcon } from 'assets/icons/trash-bin.svg';

import { IFinishEffectField } from 'order/wizard/orderStyles/interface/FinishEffectField';
import { StyleSpecifications } from 'order/wizard/orderStyles/interface/StyleSpecifications';

import { anzac, mineShaft } from 'shared/config/Colors';
import { Checkbox } from 'shared/components/Checkbox';
import { FormElement } from 'shared/components/FormElement';
import { FormLabel } from 'shared/components/FormLabel';
import { Info } from 'shared/components/Info';
import { Input } from 'shared/components/Input';
import { Select } from 'shared/components/Select';
import { SelectOptionProps } from 'shared/interface/SelectOptionProps';
import { Spacer } from 'shared/components/Layout';
import { TabbableButton } from 'shared/components/Button';
import { Tooltip } from 'shared/components/Tooltip';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useProductLineConfiguration } from 'shared/hooks/useProductLineConfiguration';
import { Wrapper } from 'shared/components/Wrapper';
import SVG from 'shared/components/SVG';
import UtilService from 'shared/services/util.service';
import WarningTooltip from 'shared/components/WarningTooltip';

import { SpecificationsOptions } from '../../../store/orderStylesReducer';
import { upchargeValidation } from '../../../../../../../shared/validations/validations';
import FormError from '../../../../../../../shared/components/FormError';

import {
  getFinishEffectsByColor,
  getFinishEffectsByFinishEffect,
  setFinishEffectOptions,
} from '../../../store/specifications/orderStylesSpecificationActions';

const FinishEffectFieldNewContainer = styled(Wrapper)``;

const FinishEffectUpchargeWrapper = styled(Wrapper)`
  position: relative;

  > ${TabbableButton} {
    position: absolute;
    right: -25px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

interface FinishEffectFieldNewProps {
  index: number;
  remove: (index?: number) => void;
  control: Control<StyleSpecifications>;
}

const FinishEffectFieldNew: FC<FinishEffectFieldNewProps> = ({
  control,
  index,
  remove,
}) => {
  const dispatch = useAppDispatch();
  const config = useProductLineConfiguration();

  const selectedProductLine = useSelector(
    (state: RootState) => state.orderStylesReducer.productLine
  );

  const canEdit = useSelector((state: RootState) => state.orderReducer.canEdit);

  const [isDirty, setIsDirty] = useState({
    selectedOption: false,
    showAvailableOnly: false,
    upcharge: false,
  } as Record<keyof IFinishEffectField, boolean>);

  const firstInArray = index === 0;

  const finishEffectOptionsKey = `finishEffectOptions${
    index + 1
  }` as keyof SpecificationsOptions;

  const finishEffectOptions = useSelector(
    (state: RootState) =>
      state.orderStylesReducer.specificationsOptions[finishEffectOptionsKey]
  );

  const { setValue, watch, getValues, formState, register } =
    useFormContext<StyleSpecifications>();

  const finishColorWatched = watch('finishColor');
  const finishEffectsWatched = watch('finishEffects');
  const woodOrMaterialWatched = watch('woodOrMaterial');
  const specialFinishSampleWatched = watch('specialFinishSample');

  const zeroOrOneCharSpecialFinishSample =
    specialFinishSampleWatched.length <= 1;

  const finishEffectField = useWatch({
    control,
    name: `finishEffects.${index}`,
  });

  const resetFields = (offset: number = 1, resetShowOnly: boolean = false) => {
    // reset both values when frist finish effect changes
    for (let i = index; i < 3; i += 1) {
      setValue(`finishEffects.${i + offset}.upcharge`, '');
      setValue(`finishEffects.${i + offset}.selectedOption`, null);

      if (resetShowOnly) {
        setValue(`finishEffects.${i + offset + 1}.showAvailableOnly`, false);
      }
    }
  };

  const resetOptions = (offset: number = 1) => {
    // reset loaded options for second finish effect
    for (let i = index + 1; i < 3; i += 1) {
      dispatch(
        setFinishEffectOptions({
          finishEffectOptions: null,
          optionNumber: (i + offset).toString(),
        })
      );
    }
  };

  const onFinishEffectOptionChangeHandler = (val: SelectOptionProps | null) => {
    // if has value set upcharge
    if (val) {
      UtilService.withDecimal(
        `finishEffects.${index}.upcharge`,
        val.upcharge!.toString(),
        setValue
      );
    } else {
      // reset current field upcharge
      setValue(`finishEffects.${index}.upcharge`, '');

      if (index < 2) {
        // reset rest of the options
        resetOptions();
      }
    }

    if (formState.isDirty) {
      resetFields();
    }
  };

  const onRemoveFinishEffectClickHandler = () => {
    for (let i = index; i < finishEffectsWatched.length; i += 1) {
      setTimeout(() => {
        remove(index < 2 ? finishEffectsWatched.length - i : i);
      }, 1);

      if (i < 2) {
        dispatch(
          setFinishEffectOptions({
            finishEffectOptions: null,
            optionNumber: (i + 2).toString(),
          })
        );
      }
    }
  };

  const loadDependentsBasedOnSelectedFinish = () => {
    const ifNextDepShowOnlySelected = getValues(
      `finishEffects.${index + 1}.showAvailableOnly`
    );

    if (
      finishEffectField.selectedOption &&
      selectedProductLine &&
      finishColorWatched &&
      woodOrMaterialWatched
    ) {
      const request = {
        finishColorId: finishColorWatched.value,
        productLineId: selectedProductLine.id,
        woodMaterialId: woodOrMaterialWatched.value,
        showOnlyAvailable: ifNextDepShowOnlySelected,
        specialFinishSample: specialFinishSampleWatched,
        optionNumber: (index + 2).toString(),
        finishEffectIds: finishEffectsWatched.reduce<string[]>((agg, curr) => {
          if (curr.selectedOption) {
            agg.push(curr.selectedOption.value);
          }

          return agg;
        }, []),
      };

      dispatch(getFinishEffectsByFinishEffect(request));
    }
  };

  const loadDependentsBasedOnShowOnly = (showAvailable?: boolean) => {
    if (selectedProductLine && finishColorWatched && woodOrMaterialWatched) {
      const request = {
        finishColorId: finishColorWatched.value,
        productLineId: selectedProductLine.id,
        woodMaterialId: woodOrMaterialWatched.value,
        showOnlyAvailable: showAvailable ?? finishEffectField.showAvailableOnly,
        specialFinishSample: specialFinishSampleWatched,
      };

      resetFields(0, true);

      if (index < 2) {
        // reset rest of the options
        resetOptions();
      }

      if (firstInArray) {
        dispatch(getFinishEffectsByColor(request));
      } else {
        dispatch(
          getFinishEffectsByFinishEffect({
            ...request,
            optionNumber: (index + 1).toString(),
            finishEffectIds: finishEffectsWatched.reduce<string[]>(
              (agg, curr) => {
                if (curr.selectedOption) {
                  agg.push(curr.selectedOption.value);
                }

                return agg;
              },
              []
            ),
          })
        );
      }
    }
  };

  const loadDependentsBasedOnStoredFields = () => {
    if (selectedProductLine && finishColorWatched && woodOrMaterialWatched) {
      const request = {
        finishColorId: finishColorWatched.value,
        productLineId: selectedProductLine.id,
        woodMaterialId: woodOrMaterialWatched.value,
        showOnlyAvailable: finishEffectField.showAvailableOnly,
        specialFinishSample: specialFinishSampleWatched,
      };

      dispatch(
        getFinishEffectsByFinishEffect({
          ...request,
          optionNumber: (index + 1).toString(),
          finishEffectIds:
            finishEffectsWatched?.reduce<string[]>((agg, curr) => {
              if (curr.selectedOption) {
                agg.push(curr.selectedOption.value);
              }

              return agg;
            }, []) ?? [],
        })
      );
    }
  };

  useEffect(() => {
    const prevFinishEffect = finishEffectsWatched?.find(
      (feWatched) => +feWatched.id === index
    );

    if (
      finishEffectField?.selectedOption === null &&
      !!prevFinishEffect?.selectedOption
    ) {
      loadDependentsBasedOnStoredFields();
    }
  }, [finishEffectField]);

  useEffect(() => {
    if (finishEffectField && isDirty.selectedOption && index < 3) {
      onFinishEffectOptionChangeHandler(finishEffectField.selectedOption);
      loadDependentsBasedOnSelectedFinish();
    }
  }, [finishEffectField?.selectedOption]);

  useEffect(() => {
    if (isDirty.showAvailableOnly) {
      loadDependentsBasedOnShowOnly();
    }
  }, [finishEffectField?.showAvailableOnly]);

  useEffect(() => {
    if (
      zeroOrOneCharSpecialFinishSample &&
      UtilService.dirtyOrTouchedFields(formState, [
        'woodOrMaterial',
        'specialFinishSample',
        'showAvailableFinishesOnly',
      ])
    ) {
      setValue(
        `finishEffects.${index}.showAvailableOnly`,
        specialFinishSampleWatched.length > 0,
        {
          shouldDirty: true,
        }
      );
    }
  }, [specialFinishSampleWatched]);

  return (
    <>
      <Input
        hidden
        {...register(`finishEffects.${index}.id`, {
          required: !!finishEffectField?.selectedOption,
        })}
      />

      <Input
        hidden
        {...register(`finishEffects.${index}.orderNumber`, {
          required: !!finishEffectField?.selectedOption,
        })}
      />

      <Wrapper flex middle>
        <>
          <Wrapper flex middle flexGrow between disabled={!canEdit}>
            <Controller
              name={`finishEffects.${index}.showAvailableOnly`}
              control={control}
              render={({ field: { onChange } }) => (
                <Checkbox
                  onChange={(e) => {
                    onChange(e.target.checked);

                    setIsDirty((prevState) => ({
                      ...prevState,
                      showAvailableOnly: true,
                    }));
                  }}
                  checked={finishEffectField?.showAvailableOnly}
                  id={`availableFinishEffects--${index + 1}`}
                  readOnly={!canEdit || (firstInArray && !finishColorWatched)}
                  title={config.styleSpecifications.avalableEffectsOnlyLabel}
                />
              )}
            />

            {(finishEffectField?.showAvailableOnly ||
              specialFinishSampleWatched.length > 0) && (
              <Info type="warning">
                <Tooltip position="left" bgColor={anzac}>
                  A door only sample is required
                </Tooltip>
              </Info>
            )}
          </Wrapper>

          <Spacer w="32px" />
        </>

        <FormLabel>Upcharge %</FormLabel>
      </Wrapper>

      <Spacer h="5px" />

      <FinishEffectFieldNewContainer flex>
        <FormElement flexGrow>
          <WarningTooltip
            fieldName="Finish Effect"
            withTooltip={UtilService.shouldShowTooltip(
              finishEffectOptions,
              finishEffectField
            )}
          >
            <Controller
              name={`finishEffects.${index}.selectedOption`}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={finishEffectOptions}
                  isDisabled={!finishEffectOptions?.length || !canEdit}
                  isClearable={firstInArray}
                  value={value}
                  data-test={`finishEffectOption--${index + 1}`}
                  onChange={(val: SelectOptionProps) => {
                    onChange(val);
                    setIsDirty((prevState) => ({
                      ...prevState,
                      selectedOption: true,
                    }));
                  }}
                />
              )}
            />
          </WarningTooltip>
        </FormElement>

        <Spacer w="24px" />

        <FormElement maxWidth={82}>
          <FinishEffectUpchargeWrapper flex middle>
            <Controller
              name={`finishEffects.${index}.upcharge`}
              control={control}
              rules={upchargeValidation()}
              render={({ field: { onChange, value } }) => (
                <Input
                  onChange={(e) => {
                    onChange(e.target.value);
                    setIsDirty((prevState) => ({
                      ...prevState,
                      upcharge: true,
                    }));
                  }}
                  value={value}
                  data-test={`finishEffectUpcharge--${index + 1}`}
                  placeholder="0.00"
                  readOnly={
                    !finishEffectOptions?.length ||
                    !finishEffectField?.selectedOption ||
                    !canEdit
                  }
                  type="text"
                  onBlur={(e) =>
                    setValue(
                      `finishEffects.${index}.upcharge`,
                      UtilService.withDecimal(null, e.target.value) as string
                    )
                  }
                  aria-invalid={
                    formState.errors.finishEffects?.[index]?.upcharge
                      ? 'true'
                      : 'false'
                  }
                />
              )}
            />

            {index > 0 && (
              <TabbableButton
                onClick={onRemoveFinishEffectClickHandler}
                type="button"
                disabled={!canEdit}
              >
                <SVG
                  icon={<TrashBinIcon />}
                  color={`${mineShaft}80`}
                  hoverColor={mineShaft}
                />
              </TabbableButton>
            )}
          </FinishEffectUpchargeWrapper>

          <FormError
            label="Finish Effect Upcharge"
            error={formState.errors.finishEffects?.[index]?.upcharge}
            validationSchema={upchargeValidation()}
          />
        </FormElement>
      </FinishEffectFieldNewContainer>
    </>
  );
};

export default FinishEffectFieldNew;
